import * as React from 'react';
import {createContext, ReactNode } from 'react';
import {UndefinedToEmpty} from "../../app/strings";

export type ThemeName = 'default' | 'flypto' | 'w88' | 'wallet88';
export type VariantName = 'light' | 'dark' | 'embedded';

export class Theme {
    name: ThemeName;
    variant: VariantName;

    constructor(name: ThemeName, variant: VariantName) {
        this.name = name;
        this.variant = variant;
    }

    inject(css: any): string {
        return `${UndefinedToEmpty(css[this.name])} ${UndefinedToEmpty(css[this.variant])}`;
    }
}

const ThemeContext = createContext<Theme>(new Theme('flypto', 'light'));
export { ThemeContext }

interface ThemeProviderOptions {
    name?: ThemeName | null | undefined,
    variant?: VariantName | null | undefined,
    children?: ReactNode | undefined
}

function ThemeProvider({name, variant, children}: ThemeProviderOptions) {
    let theme: Theme | undefined;
    if (!theme) {
        theme = new Theme(name ?? 'flypto', variant ?? 'light');
    }
    return (<ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>);
}

export default ThemeProvider;