import * as React from "react";
import {createContext, useState} from "react";
import {SiteInfo} from "../../../app/types";
import useSiteInfo from "../../Effects/SiteInfoEffect";

const SiteInfoContext = createContext<SiteInfo | undefined>(undefined);

interface SiteInfoProviderProperties {
    children: React.ReactNode;
}

function SiteInfoProvider({ children } : SiteInfoProviderProperties) {
    const [siteInfo, setSiteInfo] = useState<SiteInfo | undefined>();
    useSiteInfo(info => {
        setSiteInfo(info);
    })
    return (<SiteInfoContext.Provider value={siteInfo}>{children}</SiteInfoContext.Provider>);
}

export { SiteInfoContext };
export default SiteInfoProvider;