import {useContext, useEffect} from "react";
import {PaymentChannelContext} from "../PaymentProvider";
import {SiteInfo} from "../../app/types";

function useSiteInfo(callback: (info: SiteInfo) => void): void {
    const channel = useContext(PaymentChannelContext);
    useEffect(() => {
        if (!channel)
            return;

        let ignore = false;
        channel.getSiteInfo()
            .then(info => {
                if (ignore)
                    return;

                callback(info);
            });

        return () => {
            ignore = true;
        };
    }, [channel]);
}

export default useSiteInfo;