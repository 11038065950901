import * as React from 'react';
import {createContext, ReactNode, useEffect, useState} from 'react';
import * as signalR from "@microsoft/signalr";
import {HubConnectionState} from "@microsoft/signalr";
import * as forge from 'node-forge';
import {
    Account,
    ChallengeMessage,
    Country,
    CreditCard,
    CryptoCurrency,
    CryptoCurrencySettings,
    CryptoDictionary,
    Currency,
    EmptyPaymentDetail,
    LimitSpecifier,
    MerchantRequest,
    Method,
    MethodDictionary,
    MethodLimit,
    MethodType,
    NamedAccount,
    NetworkFeeResponse,
    PaymentResponse,
    PersonalDetails,
    QuoteMessage,
    QuoteResponse,
    ResourceSpecifier,
    ResourceTag,
    SessionUpdateMessage,
    SiteInfo,
    TaggedResource,
    UserVerifiedResponse
} from "../../app/types";
import {IHttpConnectionOptions} from "@microsoft/signalr/src/IHttpConnectionOptions";
import {useAppDispatch} from "../../app/hooks";
import {challenge, LoadIsEnabled, maintenance, paymentResponse, sessionUpdate} from "../../app/slices/Navigation";
import {quote} from "../../app/slices/Quote";
import * as jose from 'jose';
import {connected} from "../../app/actions";
import i18n from "i18next";
import {Locale} from "@w88/react-components";
import {Threads} from "../../utils/Threads";

const PaymentChannelContext = createContext<PaymentChannel | undefined>(undefined);

export {PaymentChannelContext}

export class PaymentChannel {
    connection: Promise<signalR.HubConnection>;
    resolveConnection?: (value: signalR.HubConnection | PromiseLike<signalR.HubConnection>) => void;
    rejectConnection?: (reason?: any) => void;

    constructor() {
        const that = this;
        this.connection = new Promise<signalR.HubConnection>(function (resolve, reject) {
            that.resolveConnection = resolve;
            that.rejectConnection = reject;
        });
    }

    async changeLocale(): Promise<void> {
        const lang = i18n.resolvedLanguage;
        const c = await this.ConnectedConnection();
        return c.send("changeLocale", lang);
    }

    async startSession(email: string | undefined, country: string | undefined, deviceId: string | undefined): Promise<void> {
        const lang = i18n.resolvedLanguage;
        const c = await this.ConnectedConnection();
        return c.send("startSession", email, country, lang, deviceId);
    }

    async getSiteInfo(): Promise<SiteInfo> {
        const c = await this.ConnectedConnection();
        return c.invoke<SiteInfo>("getSiteInfo");
    }

    async getMethods(): Promise<Method[]> {
        const c = await this.ConnectedConnection();
        return c.invoke<Method[]>("getMethods");
    }

    async updateFees(asset: CryptoCurrency, payee: string): Promise<MethodDictionary<string>> {
        const c = await this.ConnectedConnection();
        return c.invoke<MethodDictionary<string>>("updateFees", asset, payee);
    }

    async getEnabledCryptoCurrencies(): Promise<CryptoDictionary<CryptoCurrencySettings>> {
        const c = await this.ConnectedConnection();
        return c.invoke<CryptoDictionary<CryptoCurrencySettings>>("getEnabledCryptoCurrencies");
    }

    async getCurrency(): Promise<Currency> {
        const c = await this.ConnectedConnection();
        return c.invoke<Currency>("getCurrency");
    }

    async getAvailableBuyAndSendCountries(): Promise<Country[]> {
        const c = await this.ConnectedConnection();
        return c.invoke<Country[]>("getAvailableBuyAndSendCountries");
    }

    async getLimit(method: MethodType, asset: CryptoCurrency | undefined): Promise<MethodLimit> {
        const c = await this.ConnectedConnection();
        return c.invoke<MethodLimit>("getLimit", method, asset);
    }

    async getQuote(sellcurrencyamount: number, sellcurrency: string, purchasecurrencyamount: number, purchasecurrency: string, type: string): Promise<QuoteResponse> {
        const c = await this.ConnectedConnection();
        return c.invoke<QuoteResponse>("quotation", sellcurrencyamount, sellcurrency, purchasecurrencyamount, purchasecurrency, type);
    }

    async getEstimatedNetworkFee(assetId: string, rate: number, currency: string): Promise<NetworkFeeResponse> {
        const c = await this.ConnectedConnection();
        return c.invoke<NetworkFeeResponse>("estimatedNetworkFee", assetId, rate, currency);
    }

    async isVerified(): Promise<UserVerifiedResponse> {
        const c = await this.ConnectedConnection();
        return c.invoke<UserVerifiedResponse>("isVerified");
    }

    async isPhoneVerified(): Promise<boolean> {
        const c = await this.ConnectedConnection();
        return c.invoke<boolean>("isPhoneVerified");
    }

    async isEnabled(): Promise<boolean> {
        const c = await this.ConnectedConnection();
        return c.invoke<boolean>("isEnabled");
    }

    async isCountryAllowed(): Promise<boolean> {
        const c = await this.ConnectedConnection();
        return c.invoke<boolean>("isCountryAllowed");
    }

    async isAllowedBuyAndSend(): Promise<boolean> {
        const c = await this.ConnectedConnection();
        return c.invoke<boolean>("isAllowedBuyAndSend");
    }

    async getResource(url: string, tag: ResourceTag | undefined): Promise<TaggedResource> {
        const c = await this.ConnectedConnection();
        const data = await c.invoke<string>("getResource", url);
        return {data: data, tag: tag} as TaggedResource;
    }

    async calculateTotalAmount(method: MethodType, requestAmount: number): Promise<string> {
        const c = await this.ConnectedConnection();
        return c.invoke<string>("calculateTotalAmount", method, requestAmount);
    }

    async requestPayment(method: MethodType | undefined, amount: number | undefined, payee: string | undefined, asset: CryptoCurrency | undefined, details: CreditCard | Account | NamedAccount | EmptyPaymentDetail | undefined, deviceId: string | undefined): Promise<PaymentResponse> {
        const c = await this.ConnectedConnection();
        let pem = await c.invoke<string>("getPublicKey");
        const publicKey = forge.pki.publicKeyFromPem(pem);
        const payload = details ? forge.util.encode64(publicKey.encrypt(JSON.stringify(details), 'RSAES-PKCS1-V1_5')) : null;
        return c.invoke<PaymentResponse>("requestPayment", method, amount, payee, asset, payload, deviceId);
    }

    async authorize(userId: string, code: string): Promise<void> {
        const c = await this.ConnectedConnection();
        return c.invoke<void>("authorize", userId, code);
    }

    async sendNewCode(userId: string): Promise<void> {
        const c = await this.ConnectedConnection();
        return c.invoke<void>("resendCode", userId);
    }

    async authorizeEmailCode(userId: string, code: string): Promise<void> {
        const c = await this.ConnectedConnection();
        return c.invoke<void>("authorizeEmailCode", userId, code);
    }

    async sendNewEmailCode(email: string, realm: string): Promise<void> {
        const c = await this.ConnectedConnection();
        return c.invoke<void>("resendEmailCode", email, realm);
    }

    async register(details: PersonalDetails): Promise<void> {
        const c = await this.ConnectedConnection();
        return c.invoke<void>("register", details);
    }

    async getRaiseLimitUrl(): Promise<string> {
        const c = await this.ConnectedConnection();
        return c.invoke<string>("getRaiseLimitUrl");
    }

    async cancelPayment(): Promise<void> {
        const c = await this.ConnectedConnection();
        return c.invoke<void>("cancelPayment");
    }

    async tryAnotherMethod(): Promise<void> {
        const c = await this.ConnectedConnection();
        return c.invoke<void>("tryAnotherMethod");
    }

    async setPassword(password: string | undefined): Promise<void> {
        const c = await this.ConnectedConnection();
        const pem = await c.invoke<string>("getPublicKey");
        const publicKey = forge.pki.publicKeyFromPem(pem);
        const buffer = forge.util.createBuffer(password ?? "", "utf8");
        const payload = password ? forge.util.encode64(publicKey.encrypt(buffer.getBytes(), 'RSAES-PKCS1-V1_5')) : null;
        return c.send("setPassword", payload);
    }

    async checkPassword(password: string | undefined): Promise<void> {
        const c = await this.ConnectedConnection();
        const pem = await c.invoke<string>("getPublicKey");
        const publicKey = forge.pki.publicKeyFromPem(pem);
        const buffer = forge.util.createBuffer(password ?? "", "utf8");
        const payload = forge.util.encode64(publicKey.encrypt(buffer.getBytes(), 'RSAES-PKCS1-V1_5'));
        return c.send("checkPassword", payload);
    }

    async changeMobileNumber(email: string | undefined, mobileNumber: string | undefined, realm: string | undefined): Promise<void> {
        const c = await this.ConnectedConnection();
        return c.send("changeMobileNumber", email, mobileNumber, realm);
    }

    async changeEmail(email: string | undefined): Promise<void> {
        const c = await this.ConnectedConnection();
        return c.send("changeEmail", email);
    }

    private async ConnectedConnection() : Promise<signalR.HubConnection> {
        const c = await this.connection;
        for(let i=0; i<60; i++) {
            if (i > 0)
                await Threads.sleep(500);

            if (c.state === HubConnectionState.Connected)
                return c;
        }
        throw new Error("No connection available");
    }
}

export interface PaymentChannelEnvelope {
    channel: PaymentChannel,
    parameters: ResourceSpecifier | LimitSpecifier | undefined
}

interface PaymentProviderOptions {
    url?: string | null | undefined,
    token?: string | null | undefined,
    children?: ReactNode | undefined
}

function PaymentProvider({url, token, children}: PaymentProviderOptions) {
    const [state] = useState(new PaymentChannel());
    const dispatch = useAppDispatch();

    useEffect(() => {
        let connection = new signalR.HubConnectionBuilder()
            .withUrl((url ?? "https://wallet88.com/api") + "/pos", {
                accessTokenFactory: () => token
            } as IHttpConnectionOptions)
            .withAutomaticReconnect()
            .build();

        connection.on("challenge", (message: ChallengeMessage) => {
            dispatch(challenge(message));
        });

        connection.on("response", (message: PaymentResponse) => {
            dispatch(paymentResponse(message));
        });

        connection.on("quote", (message: QuoteMessage) => {
            dispatch(quote(message));
        });

        connection.on("sessionUpdate", (message: SessionUpdateMessage) => {
            connection.stop()
                .finally(() => {
                    dispatch(sessionUpdate(message));
                });
        });

        connection.start()
            .then(() => {
                let claims = token
                    ? jose.decodeJwt(token)
                    : undefined;

                dispatch(LoadIsEnabled(state));
                dispatch(connected({
                    application: claims ? claims['w88.application'] : undefined,
                    amount: claims ? claims['w88.amount'] : undefined,
                    currency: claims ? claims['w88.currency'] : undefined,
                    payee: claims ? claims['w88.payee'] : undefined,
                    asset: claims ? claims['w88.asset'] : undefined,
                    customerIntent: claims ? claims['w88.customerIntent'] : undefined,
                    givenName: claims ? claims['w88.givenName'] : undefined,
                    lastName: claims ? claims['w88.lastName'] : undefined,
                    email: claims ? claims['w88.email'] : undefined,
                    phone: claims ? claims['w88.phone'] : undefined,
                    dateOfBirth: claims ? claims['w88.dob'] : undefined,
                    street: claims ? claims['w88.addressStreet'] : undefined,
                    city: claims ? claims['w88.addressCity'] : undefined,
                    state: claims ? claims['w88.addressState'] : undefined,
                    country: claims ? claims['w88.addressCountry'] : undefined,
                    countryName: claims ? claims['w88.addressCountryName'] : undefined,
                    postcode: claims ? claims['w88.addressPostCode'] : undefined,
                    returnUrl: claims ? claims['w88.returnUrl'] : undefined,
                    occupation: claims ? claims['w88.occupation'] : undefined,
                    industry: claims ? claims['w88.industry'] : undefined,
                    taxId: claims ? claims['w88.document.nationalTax'] : undefined,
                    skipLogin: claims ? claims['w88.bypassLoginPage'] === 'True' : undefined,
                    enableEditPayeeInput: claims ? claims['w88.enableEditPayeeInput'] === 'True' : undefined,
                    enableEditAmountInput: claims ? claims['w88.enableEditAmountInput'] === 'True' : undefined,
                    selectedCountryIsNotRegisterCountry: claims ? claims['w88.selectedCountryIsNotRegisterCountry'] === 'True' : undefined,
                    realm: claims ? claims['w88.realm'] : undefined
                } as MerchantRequest));
            })
            .catch((e: any) => {
                console.log(e)
                dispatch(maintenance());
            });

            if (state.resolveConnection)
                state.resolveConnection(connection);
    }, [url, token, state, dispatch]);

    return <PaymentChannelContext.Provider value={state}>{children}</PaymentChannelContext.Provider>;
}

export default PaymentProvider;